/* Stile per utente non loggato */
.form-not-logged-container{
  min-height: calc(100vh - 7.7rem); /* 3.7rem(Padding Top -> .content) + FOOTER DA AGGIUNGERE */
}

/* Stile personalizzato per evidenziare gli input */
.highlight {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  border-color: #007bff;
}

/* Stile Alert */
.alert-phoenix{
  position: fixed;
  top: 5px;
  right: 24px;
  width: calc(100% - 48px);
  z-index: 1000;
  &__toast{
    top: unset;
    bottom: 10px;
  }
}

@media (min-width: 576px) {
  /* Stile per utente non loggato MOBILE */
  .form-not-logged-container{
    min-height: calc(100vh - 7.7rem); /* 3.7rem(Padding Top -> .content) + FOOTER DA AGGIUNGERE */
  }

  /* Stile Alert */
  .alert-phoenix{
    min-width: 300px;
    width: auto;
  }
}